/**
 * 项目管理-项目信息-户型维护
 * luxinwen
 * 2023-03-21
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_PROJECT_HOUSE_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.houseName" placeholder="请输入户型名称" clearable />
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.status" placeholder="请选择户型状态" clearable>
              <Option v-for="(value, key) in statusList" :key="'status' + key" :value="Number(key)">{{ value }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content" v-grant="'OSP_PROJECT_HOUSE_SAVE'">
        <Button type="primary" @click="addData">新增户型</Button>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="pic">
            <img :src="params.row.mainPicUrl" class="img-preview" @click="previewImage(params.row.mainPicUrl)">
          </template>
          <template slot-scope="params" slot="houseArea">
            <span v-show="params.row.houseArea">{{ params.row.houseArea }}m<sup>2</sup></span>
          </template>
          <template slot-scope="params" slot="houseNum">
            <span v-show="Boolean(params.row.houseNum)">{{ params.row.houseNum }}房</span>
            <span v-show="Boolean(params.row.hallNum)">{{ params.row.hallNum }}厅</span>
            <span v-show="Boolean(params.row.toiletNum)">{{ params.row.toiletNum }}卫</span>
          </template>
          <template slot-scope="params" slot="saleStatus">
            <Tag :color="params.row.saleStatus === 0 ? 'error' : 'success'">{{ saleStatusList[params.row.saleStatus] }}</Tag>
          </template>
          <template slot-scope="params" slot="status">
            <Tag :color="params.row.status === 0 ? 'error' : 'success'">{{ statusList[params.row.status] }}</Tag>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="editData(params.row, true)" v-grant="'OSP_PROJECT_HOUSE_VIEW'">查看</Button>
            <Button @click="editData(params.row, false)" v-grant="'OSP_PROJECT_HOUSE_UPDATE'">编辑</Button>
            <Button :type="params.row.saleStatus === 0 ? 'default' : 'error'" @click="changeSaleStatus(params.row)" v-grant="'OSP_PROJECT_HOUSE_SALESTATUS'">{{ params.row.saleStatus === 0 ? '在售' : '停售' }}</Button>
            <Button :type="params.row.status === 0 ? 'default' : 'error'" @click="changeStatus(params.row)" v-grant="'OSP_PROJECT_HOUSE_CHANGE'">{{ params.row.status === 0 ? '启用' : '禁用' }}</Button>
            <Button type="error" @click="removeData(params.row)" v-grant="'OSP_PROJECT_HOUSE_DELETE'">删除</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <edit-drawer v-model="drawerDisplay" :data="drawerData" :flag="drawerFlag" @success="getData"></edit-drawer>
    <modal-preview v-model="previewImageUrl"></modal-preview>
  </div>
</template>

<script>
  import { PROJECT_SALE_STATUS, PROJECT_HOUSE_STATUS } from '@/util/enum';
  import editDrawer from './edit.vue';

  export default {
    components: {
      editDrawer
    },
    data() {
      return {
        formData: {
          houseName: '', // 项目名称
          status: null, // 项目状态：1-启用，0-禁用
        },
        tableColumns: [
          {
            title: '序号',
            type: 'index',
            width: 70,
            align: 'center'
          },
          {
            title: '户型主图',
            width: 220,
            slot: 'pic'
          },
          {
            title: '户型名称',
            key: 'houseName'
          },
          {
            title: '户型建面',
            slot: 'houseArea'
          },
          {
            title: '户型居室',
            slot: 'houseNum'
          },
          {
            title: '户型朝向',
            key: 'orientation'
          },
          {
            title: '销售状态',
            slot: 'saleStatus'
          },
          {
            title: '户型状态',
            slot: 'status'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        drawerDisplay: false, // 是否显示编辑抽屉
        drawerData: {},       // 编辑抽屉数据
        drawerFlag: 'add',    // 编辑抽屉状态
        dataId: '',
        saleStatusList: PROJECT_SALE_STATUS,
        statusList: PROJECT_HOUSE_STATUS,
        previewImageUrl: ''
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_PROJECT_HOUSE_VIEW, this.$grant.OSP_PROJECT_HOUSE_UPDATE, this.$grant.OSP_PROJECT_HOUSE_SALESTATUS, this.$grant.OSP_PROJECT_HOUSE_CHANGE, this.$grant.OSP_PROJECT_HOUSE_DELETE];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.dataId = this.$route.query.id;
      if (this.dataId) this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          buildingId: this.dataId,
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.project.queryHouseList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 新增数据
       */
      addData() {
        this.drawerFlag = 'add';
        this.drawerData = {
          buildingId: this.dataId
        };
        this.drawerDisplay = true;
      },
      /**
       * 查看、编辑
       */
      editData(row, onlyRoad = false) {
        this.drawerFlag = onlyRoad ? 'show' : 'edit';
        this.drawerData = row;
        this.drawerDisplay = true;
      },
      /**
       * 在售、停售
       */
      changeSaleStatus(row) {
        let txt = row.saleStatus === 1 ? '停售' : '在售';
        let saleStatus = row.saleStatus === 1 ? 0 : 1;
        this.$confirm(`确定要将 [${row.houseName}] 设置为${txt}吗？`).then(() => {
          this.$axios({
            url: this.$api.project.changeHouseSaleStatus,
            data: {
              id: row.id,
              saleStatus
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: txt + '成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 禁用、启用
       */
      changeStatus(row) {
        let txt = row.status === 1 ? '禁用' : '启用';
        let status = row.status === 1 ? 0 : 1;
        this.$confirm(`确定要将 [${row.houseName}] 设置为${txt}吗？`).then(() => {
          this.$axios({
            url: this.$api.project.changeHouseStatus,
            data: {
              id: row.id,
              status
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: txt + '成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 删除数据
       */
      removeData(row) {
        this.$confirm(`确定要删除 [${row.houseName}] 吗？`).then(() => {
          this.$axios({
            url: this.$api.project.deleteHouse,
            data: {
              id: row.id
            },
            loading: true
          }).then(data => {
            this.notice({
              type: 'success',
              desc: '删除成功'
            });
            this.getData();
          });
        });
      },
      /**
       * 预览
       */
      previewImage(url) {
        this.previewImageUrl = url;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>