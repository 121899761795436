/**
 * 项目管理-项目信息-户型维护-编辑页
 * luxinwen
 * 2023-03-21
 */
<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="900" :before-close="beforeClose">
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="100">
        <FormItem label="户型名称" prop="houseName">
          <Input v-model.trim="formData.houseName" class="width-l" maxlength="50" clearable :disabled="isShow" />
        </FormItem>
        <FormItem label="户型图" prop="picUrlStrings">
          <sp-upload v-model="formData.picUrlStrings" :img-width="1080" :img-height="810" move :disabled="isShow"></sp-upload>
          <p class="text-tips">请上传宽高为1080*810的图片</p>
        </FormItem>
        <FormItem label="户型视频" prop="videoUrlStrings">
          <sp-upload v-model="formData.videoUrlStrings" :max-size="51200" :format="['MP4', 'WebM', 'Ogg']" video move :disabled="isShow"></sp-upload>
          <p class="text-tips">为保证播放效果，请尽量上传宽高比为3:2的视频</p>
        </FormItem>
        <FormItem label="参考价格">
          <InputNumber v-model="formData.salePriceMin" :min="0" :max="99999999" :disabled="isShow" />
          -
          <InputNumber v-model="formData.salePriceMax" :min="0" :max="99999999" :disabled="isShow" />
          m<sup>2</sup>
        </FormItem>
        <FormItem label="参考总价">
          <InputNumber v-model="formData.saleTotalPriceMin" :min="0" :max="9999999999" :disabled="isShow" />
          -
          <InputNumber v-model="formData.saleTotalPriceMax" :min="0" :max="9999999999" :disabled="isShow" />
          万/套
        </FormItem>
        <FormItem label="户型朝向" prop="orientation">
          <Input v-model.trim="formData.orientation" class="width-m" maxlength="20" clearable :disabled="isShow" />
        </FormItem>
        <FormItem label="户型面积">
          <InputNumber v-model="formData.houseArea" :min="0" :max="99999999" :disabled="isShow" /> m<sup>2</sup>
        </FormItem>
        <FormItem label="户型居室" prop="startDate">
          <InputNumber v-model="formData.houseNum" :min="0" :max="99" :disabled="isShow" /> 房
          <InputNumber v-model="formData.hallNum" :min="0" :max="99" :disabled="isShow" /> 厅
          <InputNumber v-model="formData.toiletNum" :min="0" :max="99" :disabled="isShow" /> 卫
        </FormItem>
        <FormItem label="户型介绍" prop="houseIntroduce">
          <Input v-model.trim="formData.houseIntroduce" type="textarea" :rows="6" maxlength="200" clearable :disabled="isShow" />
        </FormItem>
        <FormItem label="户型亮点" prop="houseIrightened">
          <Input v-model.trim="formData.houseIrightened" type="textarea" :rows="6" maxlength="200" clearable :disabled="isShow" />
        </FormItem>
        <FormItem label="户型详情" prop="houseDetail">
          <sp-editor v-model="formData.houseDetail" :disabled="isShow"></sp-editor>
        </FormItem>
      </Form>
      <div class="drawer-foot" v-show="!isShow">
        <Button type="primary" @click="submit">提交</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  const formData = {
    buildingId: null, // 楼盘ID
    houseNum: null, // 房间数
    hallNum: null,  // 厅数量
    toiletNum: null,  // 卫生间数
    houseArea: null,  // 户型面积
    houseDetail: '',  // 户型详情
    houseIntroduce: '', // 户型介绍
    houseIrightened: '',  // 户型亮点
    houseName: '',  // 户型名称
    mainPicUrl: '', // 户型主图，前端拿第一张图片
    orientation: '',  // 户型朝向
    picUrlStrings: [],  // 户型图集合，英文逗号分割
    salePriceMax: null, // 参数单价上限
    salePriceMin: null, // 参数单价下限
    saleStatus: null, // 销售状态：1在售0停售
    saleTotalPriceMax: null,  // 参数总价上限
    saleTotalPriceMin: null,  // 参数总价下限
    status: null, // 启用状态：1启用0禁用
    videoUrlStrings: []  // 户型视频集合，英文逗号分割
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          houseName: [
            {
              required: true,
              message: '请输入户型名称',
              trigger: 'blur'
            }
          ],
          picUrlStrings: [
            {
              type: 'array',
              required: true,
              message: '请上传户型图',
              trigger: 'change'
            }
          ],
          // videoUrlStrings: [
          //   {
          //     type: 'array',
          //     required: true,
          //     message: '请上传户型视频',
          //     trigger: 'change'
          //   }
          // ]
        },
        backupData: {}
      };
    },
    computed: {
      title() {
        return {
          'add': '新增',
          'show': '查看',
          'edit': '编辑'
        }[this.flag] + '户型';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      isAdd() {
        return this.flag === 'add';
      },
      isShow() {
        return this.flag === 'show';
      },
      isEdit() {
        return this.flag === 'edit';
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        if (this.isAdd) {
          this.initData(this.data, true);
          return;
        }
        this.$axios({
          url: this.$api.project.queryHouseDetail,
          data: {
            id: this.data.id
          },
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          let _data = Object.assign({}, this.data, data);
          this.initData(_data, true);
        });
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            this.$confirm('确定要提交吗？').then(() => {
              let url = this.$api.project.addHouse;
              let data = Object.assign({}, this.formData);
              data.mainPicUrl = data.picUrlStrings[0].url;
              data.picUrlStrings = data.picUrlStrings.map(item => item.url).join(',');
              data.videoUrlStrings = data.videoUrlStrings.map(item => item.url).join(',');
              if (this.isEdit) {
                url = this.$api.project.updateHouse;
                data.id = this.data.id;
              }
              this.save(url, data);
            });
          }
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>